import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import Cards from "../home/Cards";
import Into from "../home/Into";
import Download from "../home/Download";
import Cube from "../home/Cube";
import Cover from "../home/Cover";
import Footer from "../structure/Footer";
import Navbar from "../structure/Navbar";
import PixelMobile from "../home/PixelMobile";
import Sponsors from "../home/Sponsors";
import "../index.css";
// import CoverBanner from "../home/CoverBanner"

import popup_cierre from '../assets/banner/new/banner_cierre.webp';

const NewScrollHome = () => {
  const divRef = useRef(null);
  const [width, setWidth] = useState(getWidth());
  const [totalWidth, setTotalWidth] = useState(window.innerWidth);
  const [changeWidth, setChangeWidth] = useState(false);
  const [mobileWidth, setMobileWidth] = useState(false);
  const [showPopup, setShowPopup] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      const newTotalWidth = window.innerWidth;
      if (newTotalWidth !== totalWidth) {
        setTotalWidth(newTotalWidth);
        setWidth(getWidth());
        setChangeWidth(true);
      }

      if (window.innerWidth < 800) {
        setMobileWidth(true);
      }

      if (window.innerWidth > 800) {
        setMobileWidth(false);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    // Mostrar el popup al cargar la página
    setShowPopup(true);
  }, []);

  function getWidth() {
    return divRef?.current?.offsetWidth;
  }

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  return (
    <>
      {' '}
      <Navbar view='home' />
      {showPopup && <Popup onClose={handleClosePopup} />}
      <ScrollContainer
        className='scroll-container'
        width={changeWidth}
        mobile={mobileWidth}
      >
        <Slide width={changeWidth} mobile={mobileWidth}>
          <Cover />
          {/* <CoverBanner/> */}
        </Slide>
        {totalWidth < 800 ? (
          <Slide width={changeWidth} mobile={mobileWidth}>
            <PixelMobile />
          </Slide>
        ) : (
          <></>
        )}
        <Slide width={changeWidth} mobile={mobileWidth}>
          <Cube />
        </Slide>
        <Slide width={changeWidth} mobile={mobileWidth}>
          <Into />
        </Slide>

        <Slide width={changeWidth} mobile={mobileWidth}>
          <Cards />
        </Slide>
        {totalWidth < 1020 ? (
          <Slide width={changeWidth} mobile={mobileWidth}>
            <Sponsors />
          </Slide>
        ) : (
          <></>
        )}

        <Slide width={changeWidth} mobile={mobileWidth}>
          <Download />
        </Slide>

        <Slide width={changeWidth} mobile={mobileWidth}>
          <Footer view='home' />
        </Slide>
      </ScrollContainer>
    </>
  );
};

const ScrollContainer = styled.div`
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  scroll-snap-type: ${(props) => (props.mobile ? '' : 'y mandatory')};
  scroll-behavior: ${(props) => (props.mobile ? '' : 'smooth')};
  /* scroll-snap-type: y mandatory; */
  /* scroll-behavior: smooth; */
  position: relative;
`;

const Slide = styled.div`
  width: 100%;
  /* height: 100vh; */
  height: ${(props) => (props.mobile ? 'auto' : '100vh')};
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
  color: white;
  /* position: ${(props) => (props.width || props.mobile ? '' : 'sticky')}; */
  /* position: sticky; */
  top: 0;
  z-index: 1;
  scroll-snap-align: start;
`;

// Componente Popup
const Popup = ({ onClose }) => (
  <PopupContainer>
    <PopupContent>
      <CloseButton onClick={onClose}>&times;</CloseButton>
      <img src={popup_cierre} alt='Popup' />
    </PopupContent>
  </PopupContainer>
);

const PopupContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const PopupContent = styled.div`
  background-color: transparent;
  border-radius: 10px;
  width: 90%;
  max-width: 600px;
  max-height: 90%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  img {
    max-width: 100%;
    height: auto;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  color: white;
  border: none;
  font-size: 24px;
  cursor: pointer;
`;

export default NewScrollHome;
